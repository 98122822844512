(() => {
  const sliderCarousel = function (el: HTMLElement) {
    const thumbCarousel = el?.querySelector(
      '.slider-carousel__thumb'
    ) as HTMLElement;
    const mainCarousel = el?.querySelector(
      '.slider-carousel__main'
    ) as HTMLElement;
    const filterCtas = el?.querySelectorAll(
      '.slider-carousel__filter'
    ) as NodeListOf<HTMLButtonElement>;
    const controlCtas = el?.querySelectorAll(
      '.slider-carousel__control'
    ) as NodeListOf<HTMLButtonElement>;
    const slideContainerClass = '.aaaem-carousel__item-container';

    const _classnames = {
      activeFilter: 'slider-carousel__filter--active',
    };

    const thumbCarouselId = thumbCarousel?.getAttribute('id')!;
    const mainCarouselId = mainCarousel?.getAttribute('id')!;
    let isMobile = window.matchMedia('(max-width: 768px)').matches;

    const filters: string[] = [];

    if (filterCtas?.length) {
      filterCtas.forEach(btn => {
        filters.push(btn?.getAttribute?.('id') || '');
      });
    }

    let zoomInCta;
    let zoomOutCta;
    let zoomOutSmallCta;
    let activeFilter;

    const maxItemsInMobile = 4;
    const maxItemsInDesk = 9;

    controlCtas.forEach(cta => {
      if (cta.classList.contains('slider-carousel__control--zoom-in')) {
        zoomInCta = cta;
      } else if (cta.classList.contains('slider-carousel__control--zoom-out')) {
        zoomOutCta = cta;
      } else if (
        cta.classList.contains('slider-carousel__control--zoom-out-small')
      ) {
        zoomOutSmallCta = cta;
      }
    });

    let mainCarouselInst =
      mainCarouselId && window._tnsInstances?.[mainCarouselId];
    let thumbCarouselInst =
      thumbCarouselId && window._tnsInstances?.[thumbCarouselId];
    const mainSlideItemCollection = mainCarouselInst?.getInfo?.()?.slideItems;
    const thumbSlideItemCollection = thumbCarouselInst?.getInfo?.()?.slideItems;
    const mainCarouselSlidesHTML =
      mainSlideItemCollection && [].slice.call(mainSlideItemCollection);
    const thumbCarouselSlidesHTML =
      thumbSlideItemCollection && [].slice.call(thumbSlideItemCollection);

    // for a given filter-key and a type(main/thumb), filters the carousel images, destroys the carousel and rebuilds it
    const destroyAndRebuildCarousel = (filterKey, type) => {
      el.classList.add('loading');
      const inst = type === 'main' ? mainCarouselInst : thumbCarouselInst;
      const allSlides =
        type === 'main' ? mainCarouselSlidesHTML : thumbCarouselSlidesHTML;
      const carousel = type === 'main' ? mainCarousel : thumbCarousel;

      if (carousel) {
        inst.destroy();
        const carouselWrapper = carousel.querySelector(
          '.aaaem-carousel__content'
        )! as HTMLElement;
        let filterCount = 0;

        carouselWrapper.innerHTML = '';
        if (allSlides?.length) {
          allSlides.forEach((el, i) => {
            if (
              el
                ?.querySelector(slideContainerClass)
                ?.classList?.contains(filterKey)
            ) {
              // last few carousel items in thumb carousel are dummy to achieve the desired animation.
              // so for thumbnail carousel in mobile not adding the dummy ones, and only adding the ones that have images
              // in desktop, for thumbnail carousel adding dummy ones too, to achieve the desired effect
              if (
                type === 'main' ||
                (type === 'thumb' && isMobile !== true) ||
                (type === 'thumb' && isMobile && el.querySelector('img'))
              ) {
                carouselWrapper.append(el);
                filterCount += 1;
              }
            }
          });

          // adding additional items for replicating the animation
          if (type === 'thumb') {
            let emptyItemCount = 0;

            // empty items must be added only if there are more than the max count
            // for mobile it is always max items - 1
            // for desktop, calculate the empty slots based on how many are present
            if (
              (!isMobile && filterCount > maxItemsInDesk) ||
              (isMobile && filterCount > maxItemsInMobile)
            ) {
              if (isMobile) {
                emptyItemCount = maxItemsInMobile - 1;
              } else {
                if (filterCount % maxItemsInDesk) {
                  emptyItemCount =
                    maxItemsInDesk - (filterCount % maxItemsInDesk);
                }
              }
            }

            if (emptyItemCount) {
              for (let i = 0; i < emptyItemCount; i += 1) {
                const slide = document.createElement('div');
                slide.classList.add('aaaem-carousel__item', 'tns-item');
                carouselWrapper.append(slide);
              }
            }
          }
        }

        if (type === 'main') {
          mainCarouselInst = inst.rebuild();
          window._tnsInstances[mainCarouselId] = mainCarouselInst;
        } else {
          thumbCarouselInst = inst.rebuild();
          window._tnsInstances[thumbCarouselId] = mainCarouselInst;
        }
        el.classList.remove('loading');
      }
    };

    // adding thumb click events to trigger change in the slider when one of the image thumbnail is active
    const addThumbClickEvents = () => {
      const thumbs = thumbCarousel.querySelectorAll(
        '.aaaem-carousel__content img'
      ) as NodeListOf<HTMLElement>;

      if (thumbs.length) {
        thumbs.forEach((thumb, i) => {
          thumb.addEventListener('click', () => {
            mainCarouselInst?.goTo?.(i);
            thumbs.forEach((el, index) => {
              if (i === index) {
                el.classList.add('active');
                mainCarouselInst?.goTo?.(i);
                zoomOutCta?.click?.();
              } else if (i !== index) {
                el.classList.remove('active');
              }
            });
          });
        });
      }
    };

    // sets initial view with appropriate item selected
    const setInitialView = () => {
      const thumbs = thumbCarousel.querySelectorAll(
        '.aaaem-carousel__content img'
      ) as NodeListOf<HTMLElement>;
      const firstThumb = thumbs?.[0];
      firstThumb?.click?.();
    };

    // initiates filtering of the carousel for a given filter key
    // main function that is to be called to initiate carousel
    const filterCarouselItems = filterKey => {
      if (activeFilter === filterKey) return;

      activeFilter = filterKey;
      // making the filter button active
      filters.forEach((id, i) => {
        if (id === filterKey) {
          filterCtas[i]?.classList.add(_classnames.activeFilter);
        } else {
          filterCtas[i]?.classList.remove(_classnames.activeFilter);
        }
      });

      const hasFilters = mainCarouselSlidesHTML?.filter(e => {
        return e.querySelector(`.${filterKey}`) ? true : false;
      });

      if (hasFilters?.length) {
        thumbCarousel.classList.remove('has-no-data');
        destroyAndRebuildCarousel(filterKey, 'main');
        destroyAndRebuildCarousel(filterKey, 'thumb');
        addThumbClickEvents();
        setInitialView();
      } else {
        thumbCarousel.classList.add('has-no-data');
      }
    };

    // sets the zoom level, hides and shows the comparison sliders of the active slide for a type(in/out/out-small)
    const setZoom = type => {
      const activeSlideInfo = mainCarouselInst?.getInfo?.();
      const activeCarouselSlide =
        activeSlideInfo?.slideItems[activeSlideInfo?.index];
      const compSliders = activeCarouselSlide?.querySelectorAll(
        '.slider-carousel__comp-slider'
      );
      let comparisonSlider = activeCarouselSlide?.querySelector(
        '.slider-carousel__comp-slider--zoom-out'
      );

      if (type === 'in') {
        comparisonSlider = activeCarouselSlide?.querySelector(
          '.slider-carousel__comp-slider--zoom-in'
        );
      }

      if (compSliders?.length) {
        compSliders.forEach(compSlider => {
          compSlider.classList.remove(
            'slider-carousel__comp-slider--active',
            'slider-carousel__comp-slider--zoom-out-small-active'
          );
        });
      }

      comparisonSlider?.classList.add('slider-carousel__comp-slider--active');

      if (type === 'out-small') {
        comparisonSlider.classList.add(
          'slider-carousel__comp-slider--zoom-out-small-active'
        );
      }
    };

    // zoom CTA callback events
    const handleZoom = title => {
      if (title === 'slider-carousel-zoom-control--in') {
        setZoom('in');
        zoomOutSmallCta.removeAttribute('disabled');
      } else if (title === 'slider-carousel-zoom-control--out') {
        setZoom('out');
        zoomOutSmallCta.setAttribute('disabled', 'disabled');
      } else if (title === 'slider-carousel-zoom-control--out-small') {
        setZoom('out-small');
      }
    };

    const appendEvents = () => {
      // events for zoom control buttons and filter buttons
      window.Bus.on('emu-button:click', ({ id, title }) => {
        if (title.includes('slider-carousel-filter')) {
          filterCarouselItems(id);
        } else if (title.includes('slider-carousel-zoom-control')) {
          handleZoom(title);
        }
      });

      // event to re-initialize carousels when window is resized.
      window.addEventListener('resize', () => {
        isMobile = window.matchMedia('(max-width: 768px)').matches;
      });
    };

    const init = () => {
      // adding necessary events
      appendEvents();

      // making first filter and first slider active
      if (filters?.length) {
        filterCarouselItems(filters[0]);
      } else {
        addThumbClickEvents();
        setInitialView();
      }
    };

    init();
  };

  // method that takes a selector, returns a promise and resolves it when that element is available on DOM
  const waitForElm = (selector, el = document.body) => {
    return new Promise(resolve => {
      if (el?.querySelector(selector)) {
        return resolve(el?.querySelector(selector));
      }
      const observer = new MutationObserver(() => {
        if (el?.querySelector(selector)) {
          resolve(document?.querySelector(selector));
          observer.disconnect();
        }
      });
      observer.observe(el, {
        childList: true,
        subtree: true,
      });
    });
  };

  const initSliderCarousel = () => {
    // initiating the slider carousel
    const sliderCarouselEls = document.querySelectorAll(
      '.slider-carousel'
    ) as NodeListOf<HTMLElement>;
    sliderCarouselEls.forEach(el => {
      waitForElm(
        `.slider-carousel__thumb .aaaem-carousel__content img`,
        el
      ).then(() => {
        // stopping carousel actions to be loaded if it is author mode
        const isEditMode = document.querySelector(
          '.aem-AuthorLayer-Edit .cq-Editable-dom--container'
        );
        if (isEditMode) {
          return;
        }

        sliderCarousel(el);
      });
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSliderCarousel);
  } else {
    initSliderCarousel();
  }
})();
