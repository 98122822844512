// Stylesheets
import './main.scss';
import './components/**/*.scss';
import './resources/images/favicon.png';
import './slider-carousel';

declare global {
  interface Window {
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed
    _badgerInstances: any; //accordions
  }
}

const CONSTANTS = {
  header: {
    menuTrigger: 'hamburger-button-trigger',
    menuCloseTrigger: 'hamburger-close-button',
  },
};

(() => {
  // accordions' content when they have image is getting hidden as the height calculations are being done before the image gets loaded
  // re-calculating the height of panels when accordion is toggled, to make sure that the panels are shown without chopping off content and without any white spaces
  const handleAccordions = () => {
    const badgerInstanceKeys =
      window._badgerInstances && Object.keys(window._badgerInstances);

    if (badgerInstanceKeys) {
      badgerInstanceKeys.forEach(badgerKey => {
        const inst = window._badgerInstances[badgerKey];

        const timer = setTimeout(() => {
          inst?.calculateAllPanelsHeight?.();
          clearTimeout(timer);
        }, 400);
      });

      window.addEventListener('resize', () => {
        badgerInstanceKeys.forEach(badgerKey => {
          const inst = window._badgerInstances[badgerKey];
          inst?.calculateAllPanelsHeight?.();
        });
      });

      window.Bus.on('emu-accordion:toggle', ({ id, itemClicked }) => {
        const badgerInstance = window._badgerInstances?.[id];
        const accEl = document.querySelector(`#${id}`);
        if (accEl && badgerInstance && itemClicked?.id) {
          const itemClickedButton = accEl
            .querySelector(`#${itemClicked.id} .js-badger-accordion-header`)
            ?.getAttribute('aria-controls');
          const accordionContent = accEl.querySelector(`#${itemClickedButton}`);

          if (accordionContent) {
            badgerInstance.calculatePanelHeight(accordionContent);
          }
        }
      });
    }
  };

  const handleMenuCloseTrigger = () => {
    const menuTrigger = document.getElementById(
      CONSTANTS.header.menuTrigger
    ) as HTMLButtonElement;

    const menuCloseTrigger = document.getElementById(
      CONSTANTS.header.menuCloseTrigger
    ) as HTMLButtonElement;

    menuCloseTrigger?.addEventListener?.('click', e => {
      e.preventDefault();
      menuTrigger?.click?.();
    });
  };

  // scrolls the page to ISI when an anchor with href #isi is clicked
  const handleISITriggers = () => {
    const isiAnchors = document.querySelectorAll('[href="#isi"]');
    const isiContent = document.querySelector('.container-isi');
    const headerMenu = document.querySelector('.header__menu');
    const menuCloseTrigger = document.getElementById(
      CONSTANTS.header.menuCloseTrigger
    ) as HTMLButtonElement;

    if (isiAnchors?.length && isiContent) {
      isiAnchors.forEach(el => {
        el.addEventListener('click', e => {
          e.preventDefault();
          // closing the menu if it is opened
          if (
            menuCloseTrigger &&
            headerMenu &&
            headerMenu.classList.contains('js-toggle-on')
          ) {
            menuCloseTrigger?.click?.();
          }
          const position = isiContent.getBoundingClientRect();
          const additionalSpace = window.innerWidth < 1025 ? 85 : 105;
          window.scrollTo(0, position.top + window.scrollY - additionalSpace);
        });
      });
    }
  };

  const init = () => {
    handleMenuCloseTrigger();
    handleISITriggers();
    handleAccordions();
    if (window.Bus) {
      // to listen to events here
    }
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
